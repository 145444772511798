function utf8_to_b64(str) {
	return window.btoa(encodeURIComponent(escape(str)));
}

function b64_to_utf8(str) {
	str = str.replace(/\s/g, '');
	return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
}

$(document).ready(function () {
	var offLink,okLink;
	$(document).on('click','.offLink', function(){
		offLink = $(this).data('link');
		okLink = b64_to_utf8(offLink).toString();
		okLink = okLink.replace("__i_n_t__","?");
		window.open(okLink, '_blank');
	});
});