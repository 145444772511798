var touch = Modernizr.touch;

const menu_mobile_open = document.querySelector('#js-menu-mobile-open');
const menu_mobile_close = document.querySelector('#js-menu-mobile-close');
const menu_mobile_target = document.querySelector('#js-menu-mobile-target');
const bodyElement = document.body;

menu_mobile_open.addEventListener("click", function () {
    menu_mobile_target.classList.add("is-show");
    bodyElement.classList.add('no-scroll');
});

menu_mobile_close.addEventListener("click", function () {
    menu_mobile_target.classList.remove("is-show");
    bodyElement.classList.remove('no-scroll');
});


const animation = gsap.timeline({
    repeat: 0, // -1 para repetición infinita
    onComplete: repeatAnimation // Función para repetir la animación
});

function repeatAnimation() {
    const randomDelay = Math.random() * 10000 + 1000; // Entre 1 y 11 segundos
    setTimeout(() => {
        animation.restart(); // Reiniciar la animación
    }, randomDelay);
}

animation
    .fromTo(
        ".c-header__letter",
        { y: -50 },
        { y: 50, duration: 0.1, ease: "expo.in", stagger: 0.05 }
    )
    .fromTo(
        ".c-header__letter",
        { y: -50 },
        { y: 0, duration: 0.1, ease: "expo.in", stagger: 0.05 },
        "0.1"
    );


let selector = document.querySelectorAll('[data-anim]');

selector.forEach((element, index) => {

    let animName = element.getAttribute("data-anim");

    switch (animName) {
        case "fade-in":

            let fadeIn = gsap.to(element, {
                autoAlpha: 1,
                ease: "power4",
                duration: 2
            });

            ScrollTrigger.create({
                trigger: element,
                start: "top 80%",
                toggleActions: "play none none none",
                animation: fadeIn,
                // markers: true
            });

            break;
        case "fade-in-up":

            let fadeInUp = gsap.to(element, {
                autoAlpha: 1,
                ease: "power4",
                duration: 2,
                y: -20
            });

            ScrollTrigger.create({
                trigger: element,
                start: "top 80%",
                toggleActions: "play none none none",
                animation: fadeInUp,
                // markers: true
            });

            break;
        case "fade-in-right":

            let fadeInRight = gsap.from(element, {
                autoAlpha: 0,
                ease: "power4",
                duration: 2,
                x: 20
            });

            ScrollTrigger.create({
                trigger: element,
                start: "top 80%",
                toggleActions: "play none none none",
                animation: fadeInRight,
                // markers: true
            });

            break;
        case "fade-in-down":

            let fadeInDown = gsap.from(element, {
                autoAlpha: 0,
                ease: "power4",
                duration: 2,
                y: -20
            });

            ScrollTrigger.create({
                trigger: element,
                start: "top 80%",
                toggleActions: "play none none none",
                animation: fadeInDown,
                // markers: true
            });

            break;
        case "fade-in-left":

            let fadeInLeft = gsap.from(element, {
                autoAlpha: 0,
                ease: "power4",
                duration: 2,
                x: -20
            });

            ScrollTrigger.create({
                trigger: element,
                start: "top 80%",
                toggleActions: "play none none none",
                animation: fadeInLeft,
                // markers: true
            });

            break;
        case "zoom-in":

            let zoomIn = gsap.fromTo(element, {
                scale: 1.3,
                duration: 2,
                y: 100,
                autoAlpha: 0
            },
                {
                    scale: 1,
                    autoAlpha: 1,
                    duration: 1,
                    ease: "sine.out",
                    autoAlpha: 1,
                    y: 0
                }
            );

            ScrollTrigger.create({
                trigger: element,
                start: "top 80%",
                toggleActions: "play none none none",
                animation: zoomIn,
                // markers: true
            });

            break;
        default:

            // no animation F

            break;
    }

});

let vid = [];

if (document.querySelector('.vlite-js')) {
    Vlitejs.registerProvider('youtube', VlitejsYoutube);

    $(".vlite-js").each(function (i) {
        const idV = '#' + $(this).attr('id');
        // const pV = $(this).attr('poster');
        if ($(this).hasClass('video-youtube')) {
            vid[i] = new Vlitejs(idV, {
                options: {
                    controls: false,
                    autoplay: false,
                    playPause: true,
                    progressBar: false,
                    time: false,
                    volume: true,
                    fullscreen: true,
                    bigPlay: true,
                    playsinline: true,
                    loop: false,
                    muted: false,
                    autoHide: true,
                    // poster: pV,
                },
                provider: 'youtube',
            });
        } else {
            vid[i] = new Vlitejs(idV, {
                options: {
                    controls: false,
                    autoplay: false,
                    playPause: true,
                    progressBar: false,
                    time: false,
                    volume: true,
                    fullscreen: true,
                    bigPlay: true,
                    playsinline: true,
                    loop: false,
                    muted: false,
                    autoHide: true,
                    // poster: pV,
                },
            });
        }
    });

}


// new window.Vlitejs("#player", {
//     options: {
//         controls: false,
//         autoplay: false,
//         playPause: true,
//         progressBar: false,
//         time: false,
//         volume: true,
//         fullscreen: true,
//         bigPlay: true,
//         playsinline: true,
//         loop: false,
//         muted: false,
//         autoHide: true,
//     },
//     // plugins: ["subtitle", "pip", "cast"],
//     // onReady: function (player) {

//     //   player.on("play", () => console.log("play"));
//     //   player.on("pause", () => console.log("pause"));
//     //   player.on("progress", () => console.log("progress"));
//     //   player.on("timeupdate", () => console.log("timeupdate"));
//     //   player.on("volumechange", () => console.log("volumechange"));
//     //   player.on("enterfullscreen", () => console.log("enterfullscreen"));
//     //   player.on("exitfullscreen", () => console.log("exitfullscreen"));
//     //   player.on("enterpip", () => console.log("enterpip"));
//     //   player.on("leavepip", () => console.log("leavepip"));
//     //   player.on("trackenabled", () => console.log("trackenabled"));
//     //   player.on("trackdisabled", () => console.log("trackdisabled"));
//     //   player.on("ended", () => console.log("ended"));
//     // },
// });